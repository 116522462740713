<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Edit Client</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-client">Client </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Edit
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="validated == 1 && showButton == false"
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div v-show="validated != 1 && showButton == true">
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  @click="hideButton(false)"
                  >Detail Client</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  @click="hideButton(true)"
                  class="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  >Contact Client</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  @click="hideButton(true)"
                  class="nav-link"
                  id="pills-history-tab"
                  data-toggle="pill"
                  href="#pills-history"
                  role="tab"
                  aria-controls="pills-history"
                  aria-selected="false"
                  >History Transaksi</a
                >
              </li>
            </ul>

            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="title">Detail Client</div>
                    <div class="sub-title">Edit informasi yang diperlukan</div>
                  </div>
                </div>
                <hr />
                <div class="row mt-2">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="nama">Nama Perusahaan : </label>
                      <input
                        :disabled="validated == 1"
                        type="text"
                        v-model="formData.nama"
                        @keyup="formChange('nama')"
                        :class="{ 'is-invalid': formError && formError.nama }"
                        class="form-control"
                        id="nama"
                        placeholder="Masukkan Nama Perusahaan"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.nama"
                      >
                        {{ formError.nama }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="inputAddress2">Nomor Telepon :</label>
                      <input
                        :disabled="validated == 1"
                        type="text"
                        v-maska="'### #########################'"
                        v-model="formData.no_telp"
                        @keyup="formChange('no_telp')"
                        :class="{
                          'is-invalid': formError && formError.no_telp,
                        }"
                        class="form-control"
                        id="inputAddress2"
                        placeholder="Masukkan Nomor Telepon"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.no_telp"
                      >
                        {{ formError.no_telp }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Nomor Handphone :</label>
                      <input
                        :disabled="validated == 1"
                        type="text"
                        v-maska="'#########################'"
                        v-model="formData.no_handphone"
                        @keyup="formChange('no_handphone')"
                        :class="{
                          'is-invalid': formError && formError.no_handphone,
                        }"
                        class="form-control"
                        id="inputAddress2"
                        placeholder="Masukkan  Nomor Handphone"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.no_handphone"
                      >
                        {{ formError.no_handphone }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Nomor Whatsapp :</label>
                      <input
                        :disabled="validated == 1"
                        type="text"
                        v-maska="'########################'"
                        v-model="formData.no_whatsapp"
                        @keyup="formChange('no_whatsapp')"
                        :class="{
                          'is-invalid': formError && formError.no_whatsapp,
                        }"
                        class="form-control"
                        id="inputAddress2"
                        placeholder="Masukkan Nomor Whatsapp"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.no_whatsapp"
                      >
                        {{ formError.no_whatsapp }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Departemen :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          :disabled="validated == 1"
                          v-model="formData.id_divisi"
                          :class="{
                            'is-invalid': formError && formError.id_divisi,
                          }"
                          :options="OptionsDepartement"
                          placeholder="Pilih Departemen"
                          :settings="{ minimumResultsForSearch: -1 }"
                          @change="formChange('id_divisi')"
                          @select="formChange('id_divisi')"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.id_divisi"
                        >
                          {{ formError.id_divisi }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label for="email">Email :</label>
                      <input
                        :disabled="validated == 1"
                        type="email"
                        v-model="formData.email"
                        @keyup="formChange('email')"
                        :class="{ 'is-invalid': formError && formError.email }"
                        class="form-control"
                        id="email"
                        placeholder="Masukkan Email"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.email"
                      >
                        {{ formError.email }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">NPWP :</label>
                      <input
                        :disabled="validated == 1"
                        type="text"
                        v-maska="'##.###.###.#-###.###'"
                        v-model="formData.npwp"
                        @keyup="formChange('npwp')"
                        :class="{ 'is-invalid': formError && formError.npwp }"
                        class="form-control"
                        id="inputAddress2"
                        placeholder="Masukkan NPWP"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.npwp"
                      >
                        {{ formError.npwp }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Kategori :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          :disabled="validated == 1"
                          v-model="formData.id_kategori"
                          @change="formChange('id_kategori')"
                          @select="formChange('id_kategori')"
                          :class="{
                            'is-invalid': formError && formError.id_kategori,
                          }"
                          :options="OptionsKategori"
                          placeholder="Kategori"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.id_kategori"
                        >
                          {{ formError.id_kategori }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Status :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          :disabled="validated == 1"
                          v-model="formData.status"
                          :class="{
                            'is-invalid': formError && formError.status,
                          }"
                          :options="statusCompany"
                          placeholder="Status"
                          :settings="{ minimumResultsForSearch: -1 }"
                          @change="formChange('status')"
                          @select="formChange('status')"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.status"
                        >
                          {{ formError.status }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="title">Alamat Client</div>
                    <div class="sub-title">
                      Edit alamat client yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="inputAddress2">Provinsi :</label>
                      <Select2
                        :disabled="validated == 1"
                        v-model="formData.id_provinsi"
                        :options="OptionsProvince"
                        placeholder="Provinsi"
                        :class="{
                          'is-invalid': formError && formError.id_provinsi,
                        }"
                        @change="locationEvent($event, 'id_provinsi')"
                        @select="locationEvent($event, 'id_provinsi')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_provinsi"
                      >
                        {{ formError.id_provinsi }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Kabupaten :</label>
                      <Select2
                        :disabled="validated == 1"
                        v-model="formData.id_kabupaten"
                        :options="OptionsRegion"
                        placeholder="Kabupaten"
                        :settings="{
                          settingOption: value,
                          settingOption: value,
                        }"
                        :class="{
                          'is-invalid': formError && formError.id_kabupaten,
                        }"
                        @change="locationEvent($event, 'id_kabupaten')"
                        @select="locationEvent($event, 'id_kabupaten')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_kabupaten"
                      >
                        {{ formError.id_kabupaten }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Kecamatan :</label>
                      <Select2
                        :disabled="validated == 1"
                        v-model="formData.id_kecamatan"
                        :options="OptionsDistrict"
                        placeholder="Kecamatan"
                        :settings="{
                          settingOption: value,
                          settingOption: value,
                        }"
                        :class="{
                          'is-invalid': formError && formError.id_kecamatan,
                        }"
                        @change="locationEvent($event, 'id_kecamatan')"
                        @select="locationEvent($event, 'id_kecamatan')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_kecamatan"
                      >
                        {{ formError.id_kecamatan }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="lokasi">Alamat :</label>
                      <textarea
                        :disabled="validated == 1"
                        type="text"
                        class="form-control"
                        id="lokasi"
                        @keyup="formChange('alamat')"
                        v-model="formData.alamat"
                        :class="{ 'is-invalid': formError && formError.alamat }"
                        placeholder="Masukkan Alamat"
                      ></textarea>
                      <div
                        class="form-error"
                        v-if="formError && formError.alamat"
                      >
                        {{ formError.alamat }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div class="row mt-5">
                  <div class="col-6">
                    <div class="title">Contact Client</div>
                    <div class="sub-title">
                      Edit informasi contact client yang diperlukan
                    </div>
                  </div>
                  <div class="col-6 align-self-center text-right">
                    <router-link
                      :to="{
                        name: 'DetailContact',
                        params: { id_client: id_client },
                      }"
                      class="btn-add"
                      >+ Tambah
                    </router-link>
                  </div>
                </div>
                <hr />

                <div class="table-responsive">
                  <table
                    class="table table-bordered table-striped table-hover"
                    id="tableDepartment"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Nama</th>
                        <th style="width: 20%">Jabatan</th>
                        <th style="width: 15%">Nomor Telepon</th>
                        <th style="width: 10%">Email</th>
                        <th style="width: 5%">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, index) in listContact"
                        :key="index"
                        v-on:click="detail(value.id)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Klik untuk melihat detail"
                      >
                        <td>{{ value.nama }}</td>
                        <td>{{ value.jabatan ? value.jabatan : "-" }}</td>
                        <td>{{ value.no_telp ? value.no_telp : "-" }}</td>
                        <td>{{ value.email ? value.email : "-" }}</td>
                        <td>
                          <div
                            class="green-bedge"
                            v-if="value.status === 'aktif'"
                          >
                            Aktif
                          </div>
                          <div class="red-bedge" v-else>Nonaktif</div>
                        </td>
                      </tr>
                      <tr v-if="listContact.length == 0">
                        <td colspan="5" class="text-center">Tidak ada data</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="pills-history"
                role="tabpanel"
                aria-labelledby="pills-history-tab"
              >
                <div class="row mt-3">
                  <div class="col-6">
                    <div class="title">Transaksi History</div>
                    <div class="sub-title">
                      Edit informasi client yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="table-responsive">
                  <table class="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th style="width: 15%">Tanggal</th>
                        <th style="width: 20%">No. Transaksi</th>
                        <th style="width: 15%">Project</th>
                        <th style="width: 10%">Keterangan</th>
                        <th style="width: 15%">Mutasi Kr</th>
                        <th style="width: 15%">Mutasi Dr</th>
                        <th style="width: 15%">Saldo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in listRiwayat" :key="index">
                        <td>{{ formatDate(item.tanggal, 'DD MMMM YYYY') }}</td>
                        <td>{{ item.nomor }}</td>
                        <td>{{ item.nama_project }}</td>
                        <td>{{ item.keterangan ? item.keterangan : '-' }}</td>
                        <td>{{ item.type == 'kredit' ? formatMoney(item.nilai)  : '0'}}</td>
                        <td>{{ item.type == 'debit' ? formatMoney(item.nilai)  : '0'}}</td>
                        <td>{{ formatMoney(item.saldo) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- <ul class="nav nav-pills">
                            <li class="nav-item">
                                <router-link to="/data-client/detail" class="nav-link active">Detail Client
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/data-client/data-contact" class="nav-link">Contact Client</router-link>
                            </li>
                        </ul> -->
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import {
  get_ListDivisi,
  get_ListKabupaten,
  get_ListKecamatan,
  get_ListProvinsi,
} from "../../../actions/master";
import {
  get_DetailClient,
  get_ListKategoriClient,
  post_SaveClient,
} from "../../../actions/client";
import { checkRules, cksClient, showAlert } from "../../../helper";
import { maska } from "maska";
import moment from "moment";
import "moment/locale/id";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // TableLite
  },
  directives: {
    maska,
  },
  data() {
    return {
      validated: 1,
      showButton: false,
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ],
      optionPeriode: ["1 Bulan", "2 Bulan"],
      OptionsKategori: [],
      OptionsDistrict: [],
      OptionsRegion: [],
      OptionsDepartement: [],
      OptionsProvince: [],
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
      id_client: this.$route.params.id ? this.$route.params.id : "",
      id_company: cksClient().get("_account").id_company,
      isSubmit: false,
      formData: {
        id: "",
        id_kategori: "",
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        nama: "",
        alamat: "",
        id_provinsi: "",
        id_kecamatan: "",
        id_kabupaten: "",
        status: "aktif",
        no_telp: "",
        no_handphone: "",
        no_whatsapp: "",
        email: "",
        npwp: "",
      },
      formError: [],
      rules: {
        id_kategori: {
          required: true,
        },
        nama: {
          required: true,
        },
        alamat: {
          required: true,
        },
        id_provinsi: {
          required: true,
        },
        id_kecamatan: {
          required: true,
        },
        id_kabupaten: {
          required: true,
        },
        no_handphone: {
          required: true,
        },
        email: {
          email: true,
          required: true,
        },
      },
      listContact: [],
      listRiwayat: [],
    };
  },

  created() {
    this.getClient();
    this.getKategori();
    this.getProvinsi();
    this.getDivisi();
  },

  mounted() {
    $(".tab-pane.fade").removeClass("active show");
    if (localStorage.getItem("setContact")) {
      setTimeout(() => {
        localStorage.removeItem("setContact");
        this.hideButton(true);
        $("#pills-profile-tab").tab("show");
        $("#pills-profile").addClass("active show");
      }, 600);
    } else {
      setTimeout(() => {
        localStorage.removeItem("setContact");
        $("#pills-home-tab").tab("show");
        $("#pills-home").addClass("active show");
      }, 600);
    }

    this.tooltip();
  },
  methods: {
    hideButton(data) {
      const show = (this.showButton = data);
      if (show == true) {
        this.validated = 2;
      } else {
        this.validated = 1;
      }
    },
    editable() {
      this.validated = 2;
      this.showButton = true;
    },
    async getClient() {
      await get_DetailClient(this.id_client, (res) => {
        const { data, contact, riwayat } = res;

        this.listRiwayat = riwayat;
        
        this.getKabupaten(data.id_provinsi);
        this.getKecamatan(data.id_kabupaten);
        this.formData = { ...this.formData, ...data };
        this.listContact = contact;
      });
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date, format = 'YYYY-MM-DD') {
      if (date) {
        return moment(date).format(format)
      }
      return '-'
    },

    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        var { list } = res;
        this.OptionsDepartement = list;
      });
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    myChangeEvent(val) {
      console.log(val);
    },
    async getKategori() {
      await get_ListKategoriClient(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          var arr = [];
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            arr.push({
              id: element.id,
              text: element.nama,
            });
          }
          this.OptionsKategori = arr;
        }
      );
    },
    async getProvinsi() {
      await get_ListProvinsi(
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsProvince = arr;
        },
        () => {
          this.OptionsProvince = [];
        }
      );
    },
    async getKabupaten(id) {
      await get_ListKabupaten(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsRegion = arr;
        },
        () => {
          this.OptionsRegion = [];
        }
      );
    },

    async getKecamatan(id) {
      await get_ListKecamatan(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsDistrict = arr;
        },
        () => {
          this.OptionsDistrict = [];
        }
      );
    },
    locationEvent(val, key) {
      this.formChange(key);
      if (key === "id_provinsi") {
        this.OptionsDistrict = [];
        this.OptionsRegion = [];
        this.getKabupaten(val.id);
      } else if (key === "id_kabupaten") {
        this.OptionsDistrict = [];
        this.getKecamatan(val.id);
      }
    },

    async onSubmit() {
      for (const key in this.rules) {
        if (key != "contact" && this.rules[key]) {
          if (this.rules[key]) {
            this.rules[key].changed = true;
          }
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        this.isSubmit = true;
        await post_SaveClient(
          this.formData,
          (res) => {
            this.isSubmit = false;
            var msg =
              res.response_code == 201
                ? "Data client berhasil ditambahkan"
                : "Data Client berhasil diperbarui";
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: msg,
              onSubmit: () => {
                this.$router.push({ name: "DataClient" });
              },
            });
          },
          () => {
            this.isSubmit = false;
            showAlert(this.$swal, {
              title: "GAGAL!",
              msg: "Terjadi kesalahan, silakan ulangi kembali",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }
        );
      } else {
        this.isSubmit = false;
        setTimeout(() => {
          if ($("#pills-detail .form-error").length > 0) {
            $("#pills-detail-tab").tab("show");
            console.log(1);
          }
        }, 200);
      }
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(id_contact) {
      this.$router.push({
        name: "DetailContact",
        params: {
          id_client: this.id_client,
          id_contact: id_contact,
        },
      });
      $('[data-toggle="tooltip"]').tooltip("hide");
    },
  },
  watch: {
    id_client: function (newVal) {
      this.id_client = newVal;
      this.getClient();
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}
</style>
